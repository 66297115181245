<template>
  <div class="py-2">
    <b-nav pills card-header class="m-0 custom-tab-amg">
      <b-nav-item
        :active="!isOthersTab"
        :link-classes="['h-full px-3', bgTabsNavs]"
        @click="isOthersTab = false"
      >
        TO REMOVE
        <span v-if="toRemoveCounter > 0" class="ml-1">
          <b-badge pill variant="secondary">
            {{ toRemoveCounter > 99 ? "99+" : toRemoveCounter }}
          </b-badge>
        </span>
      </b-nav-item>
      <b-nav-item
        :active="isOthersTab"
        :link-classes="['h-full px-3', bgTabsNavs]"
        @click="isOthersTab = true"
      >
        OTHERS
        <span v-if="othersCounter > 0" class="ml-1">
          <b-badge pill variant="secondary">
            {{ othersCounter > 99 ? "99+" : othersCounter }}
          </b-badge>
        </span>
      </b-nav-item>
    </b-nav>
    <AccountsRemoveClientDashboard
      ref="AccountsRemoveClientDashboard"
      :ncrRequestId="ncrRequestId"
      :leadId="leadId"
      :idClient="idClient"
      :typeView="'REMOVE'"
      :showPdfButton="!fromCreditReport ? !isOthersTab : false"
      :isOthersTab="isOthersTab"
      :counterToRemove="G_COUNT_ANALYSIS_CR.toRemove"
      :onlyScoreId="onlyScoreId"
      :fromCreditReport="fromCreditReport"
    />
  </div>
</template>
<script>
import AccountsRemoveClientDashboard from "@/views/commons/components/request-ncr/views/components/AccountsRemoveClientDashboard.vue";
import ExportAnalysisForRemove from "@/views/commons/components/request-ncr/views/components/ExportAnalysisForRemove.vue";
import ClientsDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import ClientsServices from "@/views/credit-experts/views/clients/dashboard/services/clients.ce.dashboard.services";
import { mapGetters } from "vuex";
export default {
  props: {
    onlyScoreId: {
      type: Number,
      required: false,
      default: null,
    },
    fromCreditReport: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    AccountsRemoveClientDashboard,
    ExportAnalysisForRemove,
  },
  computed: {
    ...mapGetters({
      G_COUNT_ANALYSIS_CR: "AnalysisCrStore/G_COUNT_ANALYSIS_CR",
    }),
    toRemoveCounter() {
      return !this.fromCreditReport
        ? this.G_COUNT_ANALYSIS_CR.toRemove
        : this.only_remove_counter;
    },
    othersCounter() {
      return !this.fromCreditReport
        ? this.G_COUNT_ANALYSIS_CR.others
        : this.only_other_counter;
    },
    idClient() {
      return this.$route.params.idClient;
    },
  },
  data() {
    return {
      isOthersTab: false,
      ncrRequestId: null,
      leadId: null,
      only_remove_counter: null,
      only_other_counter: null,
    };
  },
  async created() {
    await this.getNcrDataByClient();
    if (this.fromCreditReport) {
      await this.getOnlyCounters();
    }
  },
  methods: {
    async getOnlyCounters() {
      try {
        const params = {
          idClient: this.$route.params.idClient,
          onlyScoreId: this.onlyScoreId,
          fromCreditReport: this.fromCreditReport,
        };
        const { data } = await ClientsServices.countAnalysisCr(params);
        this.only_remove_counter = data.toRemove;
        this.only_other_counter = data.others;
      } catch (error) {
        console.log(error);
      }
    },
    async getNcrDataByClient() {
      const { data } = await ClientsDashboardServices.getNcrDataByClient({
        clientId: this.idClient,
      });
      this.ncrRequestId = data[0].ncr_request_id;
      this.leadId = data[0].lead_id;
    },
  },
};
</script>
