<template>
  <div class="w-100">
    <b-card
      no-body
      class="w-100 m-0 pb-0 pt-2 px-2"
      :class="{
        'bg-dark': isDarkSkin,
        'custom-bg': !isDarkSkin,
      }"
    >
      <div v-if="showPdfButton" class="mb-1 d-flex justify-content-between">
        <div
          v-for="(counter, index) in countersArray"
          class="counters-border float-right d-flex"
          :style="{
            'border-color': counterStyles[counter.key],
          }"
          :key="index"
        >
          <div
            class="counters-text text-center"
            :style="{ 'background-color': counterStyles[counter.key] }"
          >
            {{ counter.label }}
          </div>
          <h2
            class="counters-content"
            :style="{ 'background-color': counterStyles[counter.key] }"
          >
            {{ counters[counter.key] }}
          </h2>
        </div>
        <export-analysis-for-remove
          :approvedAccounts="true"
          :ncrRequestId="ncrRequestId"
          :leadId="leadId"
          :qtyRemove="counterToRemove"
          :idClient="idClient"
          :programClient="client.program_id"
        />
      </div>
      <b-table
        :items="removeData"
        :fields="visibleFields"
        :sticky-header="'50vh'"
        :foot-clone="false"
        show-empty
        class="custom-table"
        :class="{ 'row-light': !isDarkSkin }"
      >
        <template #cell(id)="{ index, item }">
          <div
            class="d-flex justify-content-center align-items-center font-size-md custom-height"
            style="min-width: 0px"
          >
            {{ index + 1 }}
          </div>
          <ul class="list-unstyled">
            <li
              v-for="(label, index) in bureauDetails(item)"
              :key="index"
              class="padding w-100 d-flex justify-content-center text-center text-capitalize"
            >
              <div
                style="width: 50px !important"
                class="d-flex justify-content-center align-items-center"
              >
                <span class="text-truncate w-100" style="opacity: 0">{{
                  label.text
                }}</span>
              </div>
            </li>
          </ul>
        </template>
        <template #head(transunion)>
          <b-img
            :src="require('@/assets/images/icons/transunion.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'TRANSUNION'"
          />
        </template>
        <template #head(experian)>
          <b-img
            :src="require('@/assets/images/icons/experian.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'EXPERIAN'"
          />
        </template>
        <template #head(equifax)>
          <b-img
            :src="require('@/assets/images/icons/equifax.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'EQUIFAX'"
          />
        </template>

        <template #cell(accounts)="{ item }">
          <!--REMOVE -->
          <ul
            class="list-unstyled w-100 m-0"
            style="min-width: 200px !important"
          >
            <li
              class="w-100 d-flex align-items-center justify-content-between custom-height"
              style="font-weight: bolder"
            >
              <div class="w-100 position-relative">
                <div
                  v-for="(label, index) in generalInfo(item)"
                  :key="index"
                  class="w-100"
                >
                  <div
                    class="w-100 d-flex flex-column justify-content-start text-capitalize text-center"
                    v-if="label.key === 'see_more'"
                  >
                    <div
                      class="d-flex flex-column text-center justify-content-center w-100 font-size-xmd font-weight-bolder"
                    >
                      {{ getRowCreditorName(item) }}
                    </div>
                    <span
                      class="w-100 mt-50 text-capitalize px-1"
                      :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                      style="font-size: 12px"
                    >
                      Account #: {{ getRowCreditorAccount(item) }}
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <template v-for="(label, index) in bureauDetails(item)">
              <li
                :key="index"
                class="padding w-100 d-flex justify-content-center text-center text-capitalize"
              >
                <div
                  style="width: 130px !important"
                  class="d-flex justify-content-center align-items-center"
                >
                  <span class="text-truncate d-flex w-100">{{
                    label.text
                  }}</span>
                </div>
              </li>
            </template>
          </ul>
        </template>
        <template #cell(classification_ncr)="{ item }">
          <div
            v-if="!item.editClassification"
            class="d-flex justify-content-center align-items-center h-100 custom-height"
          >
            <h6
              class="text-uppercase font-weight-bolder text-center m-0"
              :class="item.classification_ncr == null ? 'text-danger' : ''"
              :style="`color: ${textColor[item.classification_ncr_id]}`"
            >
              {{ item.classification_ncr || "NOT QUALIFIED" }}
              {{ item.classification_ncr_id }}
            </h6>
          </div>
          <ul class="list-unstyled">
            <li
              v-for="(label, index) in bureauDetails(item)"
              :key="index"
              class="padding w-100 d-flex justify-content-center text-center text-capitalize"
            >
              <div style="width: 50px !important">
                <span class="text-truncate w-100" style="opacity: 0">{{
                  label.text
                }}</span>
              </div>
            </li>
          </ul>
        </template>

        <template #cell(transunion)="{ item }">
          <DataBureauClientDashboard
            :item="item.items[0]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              statuses,
            }"
            :isOthersTab="isOthersTab"
            :hasNcr="item.has_ncr"
            :ncr_request_id="item.ncr_request_id"
            :from_credit_report="fromCreditReport"
            @updateStatusLetter="updateStatusLetter"
            @openModalTracking="openModalTracking"
            @editAccount="editAccount"
            @deleteAccount="deleteAccount"
          />
        </template>

        <template #cell(experian)="{ item }">
          <DataBureauClientDashboard
            :item="item.items[1]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              statuses,
            }"
            :hasNcr="item.has_ncr"
            :ncr_request_id="item.ncr_request_id"
            :isOthersTab="isOthersTab"
            :from_credit_report="fromCreditReport"
            @updateStatusLetter="updateStatusLetter"
            @openModalTracking="openModalTracking"
            @editAccount="editAccount"
            @deleteAccount="deleteAccount"
          />
        </template>

        <template #cell(equifax)="{ item }">
          <DataBureauClientDashboard
            :item="item.items[2]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              statuses,
            }"
            :hasNcr="item.has_ncr"
            :ncr_request_id="item.ncr_request_id"
            :isOthersTab="isOthersTab"
            :from_credit_report="fromCreditReport"
            @updateStatusLetter="updateStatusLetter"
            @openModalTracking="openModalTracking"
            @editAccount="editAccount"
            @deleteAccount="deleteAccount"
          />
        </template>

        <template #cell(detail)="{ item }">
          <div
            style="width: 40px !important"
            class="d-flex justify-content-center align-items-center custom-height"
          >
            <feather-icon
              icon="ChevronDownIcon"
              class="hover-me cursor-pointer"
              size="22"
              :class="{ 'rotate-180': item.showDetails }"
              @click="item.showDetails = !item.showDetails"
            />
          </div>
          <ul class="list-unstyled overflow-hidden">
            <li
              v-for="(label, index) in bureauDetails(item)"
              :key="index"
              class="padding w-100 d-flex justify-content-center text-center text-capitalize"
            >
              <div style="width: 50px !important">
                <span class="text-truncate w-100 d-none">{{ label.text }}</span>
              </div>
            </li>
          </ul>
        </template>
      </b-table>
    </b-card>
    <modal-tracking-letter
      v-if="modalTrackingController"
      :pItem="itemAcc"
      :pType="4"
      :client-name="client.client_name"
      :account="client.account"
      @close="closeModalTracking"
    />
    <edit-account-modal
      v-if="editAcModalController"
      :user-id="currentUser.user_id"
      :item-id="itemIdToSend"
      :data-client="dataClient"
      :showAdditionalInputs="true"
      @reload="myProvider"
      @close="closeEditAccount()"
    />
  </div>
</template>
<script>
import ModalTrackingLetter from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalTrackingLetter.vue";
import EditAccountModal from "@/views/commons/components/clients/dashboard/options/report-module/modals/EditAccountModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import fields from "@/views/commons/components/request-ncr/data/result-analysis-ncr.fields";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";
import LabelsRemove from "@/views/commons/components/request-ncr/data/result-analysis-cr-labels-remove";
import { mapGetters, mapActions } from "vuex";
import ExportAnalysisForRemove from "@/views/commons/components/request-ncr/views/components/ExportAnalysisForRemove.vue";
import DataBureauClientDashboard from "./DataBureauClientDashboard.vue";

export default {
  props: {
    fromCreditReport: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyScoreId: {
      type: Number,
      required: false,
      default: null,
    },
    counterToRemove: {
      type: Number,
      default: 0,
    },
    isOthersTab: {
      type: Boolean,
      default: false,
    },
    leadId: {
      type: Number,
      require: true,
      default: 0,
    },
    ncrRequestId: {
      type: Number,
    },
    idClient: {
      default: null,
      require: false,
      type: String,
    },
    openBySeller: {
      type: Boolean,
      default: false,
    },
    typeView_: {
      type: String,
      default: "REMOVE",
    },
    showPdfButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataBureauClientDashboard,
    ExportAnalysisForRemove,
    ModalTrackingLetter,
    EditAccountModal,
  },
  data() {
    return {
      editAcModalController: false,
      modalTrackingController: false,
      countersArray: [
        {
          key: "deleted_counter",
          label: "DELETED",
        },
        {
          key: "updated_counter",
          label: "UPDATED",
        },
        {
          key: "dispute_counter",
          label: "IN DISPUTE",
        },
        {
          key: "negative_counter",
          label: "NEGATIVE",
        },
        {
          key: "verified_counter",
          label: "VERIFIED",
        },
      ],
      counterStyles: {
        deleted_counter: "#00F380",
        updated_counter: "#8F5FE8",
        dispute_counter: "#FFAF00",
        negative_counter: "#FC424A",
        verified_counter: "#FC424A",
      },
      counters: {},
      statuses: [],
      labelsRemove: LabelsRemove,
      typeView: null,
      showDetails: {},
      fields: fields,
      items: [],
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      priorityColor: {
        high: "#00F380",
        medium: "#FFAF00",
        low: "#FE0000",
        SETTLE: "#00F380",
        "NEEDS CONFIRMATION": "#FFAF00",
        "NOT SETTLE": "#FE0000",
      },
      accountResultData: {},
      removeOptions: [
        {
          label: "MEDIUM",
          value: "MEDIUM",
        },
        {
          label: "HIGH",
          value: "HIGH",
        },
      ],
      removeData: [],
      approvedAccounts: false,
      appointmentExist: true,
      qtyRemove: 0,
      classifications: [],
      dataClient: {},
      itemAcc: null,
      itemIdToSend: null,
    };
  },
  watch: {
    isOthersTab: {
      handler() {
        this.myProvider();
      },
    },
    refreshToRemove(newVal) {
      if (newVal) {
        this.myProvider();
        this.$store.dispatch("NewItemsStore/A_REFRESH_TO_REMOVE", false);
      }
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      refreshToRemove: "NewItemsStore/G_REFRESH_TO_REMOVE",
    }),
    visibleFields() {
      this.modifyVisibility("state", !this.isDashboardClient);
      this.modifyVisibility("transunion", this.typeView == "REMOVE");
      this.modifyVisibility("experian", this.typeView == "REMOVE");
      this.modifyVisibility("equifax", this.typeView == "REMOVE");
      this.modifyVisibility("balance", this.typeView == "SETTLEMENTS");
      this.modifyVisibility("high_credit", this.typeView == "SETTLEMENTS");
      this.modifyVisibility("average", this.typeView == "SETTLEMENTS");
      this.modifyVisibility("priority", this.typeView == "SETTLEMENTS");
      this.fields.forEach((x) => {
        if (x.key === "accounts") {
          if (this.typeView != "REMOVE") {
            x.tdClass = "pt-2 col-4";
          } else {
            x.tdClass = "p-0 col-2";
          }
        }
      });
      return this.fields.filter((item) => item.visible);
    },
    analysisEvaluationForTab() {
      return this.typeView == "REMOVE"
        ? this.accountResultData.status_result_remove
        : this.accountResultData.status_result_negotiate;
    },
    labelsRemoveVisible() {
      this.modifyVisibilityLabelRemove("select", this.openBySeller);
      return this.labelsRemove.filter((x) => x.visible);
    },
    isDashboardClient() {
      return !!this.$route.params.idClient;
    },
  },
  async mounted() {
    await this.getStatuses();
    this.typeView = this.typeView_;
    await this.myProvider();
    await this.getClassifications();
  },
  methods: {
    ...mapActions({
      updateEfectivity: "ReportStore/A_REFRESH_EFECTIVITY",
      A_GET_CLIENTS: "DebtSolutionClients/A_GET_CLIENTS",
      A_COUNT_ANALYSIS_CR: "AnalysisCrStore/A_COUNT_ANALYSIS_CR",
    }),
    async getClassifications() {
      const { data } = await NcrRequestService.getClassificationNcr();
      this.classifications = data;
    },
    async saveNewClassification(item) {
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) {
        item.classification_ncr = null;
        item.editClassification = false;
        return;
      }

      const params = {
        cr_account_ac_id: item.id,
        classification_ncr: item.classification_ncr,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.saveNewClassification(params);
        if (data.success) {
          this.myProvider();
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
        } else {
          this.showToast(
            "error",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getCountAccountsToRemove() {
      const params = {
        ncrRequestId: 0,
        idClient: this.$route.params.idClient,
        typeView: "REMOVE",
        leadId: 0,
        toViewRates: false,
      };
      await this.A_COUNT_ANALYSIS_CR(params);
    },
    openModalTracking(item) {
      this.itemAcc = item;
      this.modalTrackingController = true;
    },
    closeModalTracking() {
      this.modalTrackingController = false;
    },
    editAccount(item) {
      this.dataClient = item;
      this.editAcModalController = true;
      this.itemIdToSend = item.id;
    },
    async closeEditAccount() {
      await this.A_GET_CLIENTS({
        id: this.$route.params.idClient,
      });
      this.editAcModalController = false;
    },
    async deleteAccount(item) {
      try {
        const response = await this.showConfirmSwal(
          `${item.creditor_name} - ${item.bureau_name}`,
          `Are you sure to delete this item?`
        );
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteDelteAccountAc({
            iduser: this.currentUser.user_id,
            id: item.id,
            client_account_id: this.$route.params.idClient,
          });
          if (data.status === 200) {
            this.showSuccessSwal("Account has been deleted successfully");
            await this.A_GET_CLIENTS({
              id: this.$route.params.idClient,
            });
            this.updateEfectivity(true);
            await this.getCountAccountsToRemove();
            await this.myProvider();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
    async updateStatusLetter(status) {
      const response = await this.showConfirmSwal(
        `${status.creditor_name} - ${status.bureau_name}`,
        `Are you sure to change the status to ${status.value}?`
      );
      if (!response.isConfirmed) return;
      try {
        const data = await NcrRequestService.updateStatusLetter(status);
        if (data.status === 200) {
          await this.A_GET_CLIENTS({
            id: this.$route.params.idClient,
          });
          await this.myProvider();
          this.showSuccessSwal();
        }
      } catch (e) {
        this.showErrorSwal(e);
        console.log(e);
      }
    },
    async getStatuses() {
      try {
        const data = await ClientsOptionsServices.getStatusLetters();
        if (data.status === 200) {
          this.statuses = data.data.filter(
            (status) =>
              status.value == "DELETED" ||
              status.value == "UPDATED" ||
              status.value == "IN DISPUTE" ||
              status.value == "NEGATIVE"
          );
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    generalInfo(item) {
      return this.filteredLabels(item).filter((label) => !label.info);
    },
    bureauDetails(item) {
      return this.filteredLabels(item).filter((label) => label.info);
    },
    filteredLabels(item) {
      return !item.showDetails
        ? this.labelsRemoveVisible.slice(
            0,
            this.checkKeyExists(this.labelsRemoveVisible, "select") ? 3 : 2
          )
        : this.labelsRemoveVisible;
    },
    getRowCreditorName({ items }) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.creditor_name : null;
    },
    getRowCreditorAccount({ items }) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.account_number : null;
    },
    async myProvider() {
      try {
        this.addPreloader();
        const params = {
          idClient: this.idClient,
          isOthers: this.isOthersTab,
          onlyScoreId: this.onlyScoreId,
          fromCreditReport: this.fromCreditReport,
        };
        const { data } = await NcrRequestService.getAnalysisClientDashboard(
          params
        );
        this.removeData = data.remove_data.map((item) => {
          item.items.map(
            (item) => (
              (item.is_remove = item.is_remove == 1 ?? true),
              (item.missing_rules = [
                "balance",
                "monthly_payment",
                "payment_status",
                "credit_limit",
              ])
            )
          );
          return {
            ...item,
            editClassification: false,
            showDetails: false,
          };
        });
        this.counters = data.counters;
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("Error in myProvider: ", error);
      }
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.fields[keyField].visible = isVisible;
      }
    },
    modifyVisibilityLabelRemove(nameKey, isVisible) {
      let keyField = this.labelsRemove.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.labelsRemove[keyField].visible = isVisible;
      }
    },
    checkKeyExists(array, selectValue) {
      return array.some((field) => field.key === selectValue);
    },
  },
};
</script>
<style scoped>
* {
  --customBgSuccess: #00c04f;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 9;
}
.custom-card {
  transform: translateY(-15px);
  border-radius: 0px !important;
}
.custom-img {
  filter: brightness(0) invert(1);
  height: 20px !important;
  width: 20px !important;
}
.custom-font-size {
  font-size: 13px !important;
}
.custom-bg {
  background-color: #eff3fc !important;
  box-shadow: none !important;
}
.custom-table {
  margin: 0px 10px 25px 10px !important;
  border-radius: 10px !important;
}
.hidden-x {
  overflow-x: hidden !important;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  color: white;
}
.custom-table tbody tr td {
  border: 1px solid gray !important;
}
.custom-table .table tr td:first-child,
.custom-table-dark .table tr td:first-child {
  border-radius: 0px !important;
}
.row-light >>> tbody tr {
  background-color: white !important;
}
li.padding {
  height: 35px;
  border-top: 0.5px solid #e3e5ec !important;
}
.custom-height {
  height: 100px !important;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.icon {
  animation: heartbeat 1s infinite;
}
.font-size-md {
  font-size: 13px;
}
.font-size-xmd {
  font-size: 12px;
}
.font-size-sm {
  font-size: 11px !important;
}
.rotate-180 {
  transform: rotate(180deg) scale(1.3);
  color: #007deb !important;
}
.custom-squad {
  width: 12px;
  height: 12px;
  display: flex;
}
.custom-bg-logo {
  opacity: 0.3 !important;
}
.table.b-table > tbody > tr {
  position: relative !important;
}
.table.b-table > tbody > tr.b-table-details > td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.table.b-table > tbody .b-table-row-selected.table-active td {
  background-color: #ebeefc !important;
}
.custom-icon {
  width: 15px;
  height: 15px;
}
.custom-icon-md {
  width: 19px;
  height: 19px;
}
.size-legends {
  width: 19px !important;
  height: 19px !important;
}
.font-size-legend {
  font-size: 15px !important;
}
ul {
  margin: 0;
}
.counters-border {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid;
}
.counters-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0 3px 0 0;
  font-size: 13px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}
.counters-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.custom-button {
  background-color: #3f7afa;
  color: white;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
