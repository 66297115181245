<template>
  <ul class="list-unstyled w-100 m-0">
    <li
      class="w-100 d-flex flex-column justify-content-between align-items-center custom-height"
    >
      <div class="d-flex justify-content-center align-items-center flex-grow-1">
        <!--READ ONLY PRIORITY-->
        <template v-if="item.id != '-'">
          <b-dropdown right no-caret variant="link">
            <template #button-content>
              <div
                class="text-white d-flex justify-content-between align-items-center px-50 py-50 rounded-xlg position-relative"
                style="width: 170px; position: relative; transform: scale(0.9)"
                :style="
                  item.priority
                    ? `background-color: ${
                        others.priorityColor[item.priority.toLowerCase()]
                      } !important;`
                    : 'background-color: rgb(130, 134, 139)'
                "
              >
                <b-badge
                  class="text-capitalize custom-badge"
                  :variant="
                    item.id != '-'
                      ? variantBadge[item.status_letter]
                      : 'light-info'
                  "
                >
                  {{ item.status_letter }}
                  {{
                    item.saved_new_item_at && isOthersTab ? "(NEW ITEM)" : ""
                  }}
                  {{ !hasNcr ? "(w/o NCR)" : "" }}
                  <tabler-icon
                    v-b-tooltip.hover.right="'Letter'"
                    v-if="[1, 2].includes(item.type_send)"
                    icon="MailIcon"
                    size="20"
                  />
                  <feather-icon
                    v-b-tooltip.hover.right="'Direct'"
                    v-if="[3, 4, 5].includes(item.type_send)"
                    icon="PhoneIcon"
                    size="20"
                  />
                </b-badge>
                <div
                  class="d-flex justify-content-start align-items-center w-100"
                >
                  <tabler-icon icon="CircleCheckIcon" size="20" />
                  <span
                    v-if="item.priority"
                    class="text-uppercase font-weight-bolder p-0 mx-1 text-white"
                  >
                    {{ item.priority }}
                    {{
                      [28, 29].includes(moduleId)
                        ? `- ${Math.round(item.percentage)} %`
                        : ``
                    }}
                  </span>
                  <span v-else> NOT QUALIFIED </span>
                </div>
                <feather-icon
                  v-b-tooltip.hover.right="'Actions'"
                  icon="SettingsIcon"
                  size="16"
                  class="align-middle text-white"
                  :class="
                    isSpecialist &&
                    isOthersTab &&
                    (item.classification_ncr == null ||
                      item.type_classification == null) &&
                    item.status_letter != 'POSITIVE'
                      ? 'oscillate'
                      : ''
                  "
                />
              </div>
            </template>
            <b-dropdown-item
              v-b-modal.modal-primary
              @click="$emit('openModalTracking', item)"
            >
              <feather-icon
                icon="ListIcon"
                size="16"
                class="mr-1 cursor-pointer text-end text-info"
              />
              <span class="align-middle">View Tracking</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="isSpecialist && !from_credit_report"
              v-b-modal.modal-primary
              @click="
                $emit('editAccount', {
                  ...item,
                  ncr_request_id: ncr_request_id,
                })
              "
            >
              <feather-icon
                size="16"
                class="mr-1 cursor-pointer text-end text-warning"
                icon="EditIcon"
              />
              <span class="align-middle">Edit Account</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="isSpecialist && !from_credit_report"
              v-b-modal.modal-primary
              @click="
                $emit('deleteAccount', {
                  id: item.id,
                  creditor_name: item.creditor_name,
                  bureau_name: item.bureau_name,
                })
              "
            >
              <feather-icon
                size="16"
                class="mr-1 cursor-pointer text-danger"
                icon="TrashIcon"
              />
              <span class="align-middle">Delete Account</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-for="status in others.statuses"
              :key="status.id"
              @click="
                $emit('updateStatusLetter', {
                  cr_detail_id: item.id,
                  status_id: status.id,
                  value: status.value,
                  creditor_name: item.creditor_name,
                  bureau_name: item.bureau_name,
                  user_id: currentUser.user_id
                })
              "
              :disabled="status.value == item.status_letter"
            >
              {{ status.value }}
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
        <template v-else>
          <!--PLACEHOLDER OF BUREAU -->
          <img
            v-if="item.bureau_id == 1"
            :src="require('@/assets/images/icons/transunion.png')"
            width="30px"
            height="30px"
            class="custom-bg-logo"
          />
          <img
            v-else-if="item.bureau_id == 2"
            :src="require('@/assets/images/icons/experian.png')"
            width="30px"
            height="30px"
            class="custom-bg-logo"
          />
          <img
            v-else-if="item.bureau_id == 3"
            :src="require('@/assets/images/icons/equifax.png')"
            width="30px"
            height="30px"
            class="custom-bg-logo"
          />
        </template>
      </div>
    </li>
    <!--DETAILS OF BUREAU-->
    <template v-for="(lbl, key) in bureauDetails">
      <li
        class="padding w-100 d-flex justify-content-center text-capitalize text-center"
        :key="key"
      >
        <template v-if="lbl.key === 'payment_status'">
          <span
            v-if="item.payment_status"
            class="text-truncate d-flex justify-content-center align-items-center"
            style="max-width: 200px"
            :id="`p_status_${item.id}`"
          >
            {{
              item.payment_status.length > maxLetter
                ? `${item.payment_status.slice(0, maxLetter)}...`
                : item.payment_status
            }}
            <b-tooltip
              v-if="item.payment_status.length > maxLetter"
              :target="`p_status_${item.id}`"
            >
              {{ item.payment_status }}
            </b-tooltip>
          </span>
          <span class="d-flex justify-content-center align-items-center" v-else>
            {{ "-" }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'comments'">
          <span
            v-if="item.comments"
            class="text-truncate d-flex justify-content-start align-items-center"
            style="max-width: 180px"
            :id="`comment_${item.id}`"
          >
            {{
              item.comments.length > maxLetter
                ? `${item.comments.slice(0, maxLetter)}...`
                : item.comments
            }}
            <b-tooltip
              v-if="item.comments.length > maxLetter"
              :target="`comment_${item.id}`"
            >
              {{ item.comments }}
            </b-tooltip>
          </span>
          <span class="d-flex justify-content-center align-items-center" v-else>
            {{ "-" }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'balance'">
          <span
            class="text-truncate d-flex justify-content-center align-items-center"
            style="max-width: 200px"
          >
            $ {{ isNaN(item.balance) ? 0 : item.balance | currency }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'monthly_payment'">
          <span
            class="text-truncate d-flex justify-content-center align-items-center"
            style="max-width: 200px"
          >
            $
            {{
              isNaN(item.monthly_payment) ? 0 : item.monthly_payment | currency
            }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'credit_limit'">
          <span
            class="text-truncate d-flex justify-content-center align-items-center"
            style="max-width: 250px"
          >
            $
            {{ isNaN(item.credit_limit) ? 0 : item.credit_limit | currency }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'h_credit'">
          <span
            class="text-truncate d-flex justify-content-center align-items-center"
            style="max-width: 180px"
          >
            $ {{ isNaN(item.h_credit) ? 0 : item.h_credit | currency }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'creditor_name'">
          <span
            class="text-truncate d-flex justify-content-start align-items-center"
            style="max-width: 180px"
            :id="`account_${item.id}`"
          >
            {{
              item.creditor_name.length > maxLetter
                ? `${item.creditor_name.slice(0, maxLetter)}...`
                : item.creditor_name
            }}
            <b-tooltip
              v-if="item.creditor_name.length > maxLetter"
              :target="`account_${item.id}`"
            >
              {{ item.creditor_name }}
            </b-tooltip>
          </span>
        </template>
        <template v-else>
          <span
            class="text-truncate d-flex justify-content-center align-items-center"
            style="max-width: 250px"
            >{{ item[lbl.key] }}</span
          >
        </template>
      </li>
    </template>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "DataBureauClientDashboard",
  props: {
    from_credit_report: {
      type: Boolean,
      default: false,
    },
    hasNcr: {
      type: Boolean,
      default: true,
    },
    ncr_request_id: {
      default: null,
      required: false,
    },
    isOthersTab: {
      type: Boolean,
      default: false,
    },
    labelsRemove: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    others: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      variantBadge: {
        UPDATED: "info",
        DELETED: "success",
        "IN DISPUTE": "warning",
        NEGATIVE: "danger",
        VERIFIED: "danger",
        POSITIVE: "primary",
      },
      maxLetter: 20,
    };
  },
  watch: {
    "item.is_remove": {
      handler() {
        this.item.removed =
          this.item.is_remove && !!this.item.selected_negotiate;
      },
      deep: true,
    },
  },
  methods: {},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      appointmentExist: "RequestNcrStore/G_APPOINTMENT_EXIST",
    }),
    generalInfo() {
      return this.labelsRemove.filter((label) => !label.info);
    },
    bureauDetails() {
      return this.labelsRemove.filter((label) => label.info);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
  },
  mounted() {
    this.item.removed = this.item.is_remove && !!this.item.selected_negotiate;
  },
};
</script>
<style scoped>
.dropdown >>> button {
  padding: 0 !important;
}
li.padding {
  height: 35px;
  /* padding: 0.5rem !important; */
  border-top: 1px solid #e3e5ec !important;
}
.custom-bg-logo {
  opacity: 0.3 !important;
  filter: grayscale(100%) !important;
}
.rounded-xlg {
  border-radius: 13px !important;
}
.custom-badge {
  position: absolute !important;
  top: -13px;
  right: 0px !important;
}
.custom-height {
  height: 100px !important;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
